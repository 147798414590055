<template>
    <div>
        <div class="wz">
            <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>大数据管理</el-breadcrumb-item>
            <el-breadcrumb-item>供需关系数据</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="box">
            <div class="topchange">
                <el-input size="small" v-model="num" placeholder="请输入一个数字" style="width:200px;margin-right:5px;"  onkeyup="value=value.replace(/[^\d\.]/g,'')" ></el-input><el-button type="primary" size="small" @click="changedata">更改数据</el-button><el-button type="primary" size="small" @click="resetdata">重置数据</el-button>
            </div>
            <div class="listtitle"><strong>上饶人才总数</strong></div>
            <div class="cont">
                <el-input size="small" v-model="list[0].percentage" onkeyup="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}" placeholder="总数" style="width:200px; margin-right:10px;"></el-input>
                <el-input size="small" v-model="list[0].percentageMark" onkeyup="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}" placeholder="昨日新增" style="width:200px;margin-right:10px;"></el-input>
            </div>
            <div class="listtitle" style="margin-top:10px;"><strong>上饶企业总数</strong></div>
            <div class="cont">
                <el-input size="small" v-model="list[1].percentage" onkeyup="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}" placeholder="总数" style="width:200px; margin-right:10px;"></el-input>
                <el-input size="small" v-model="list[1].percentageMark" onkeyup="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}" placeholder="昨日新增" style="width:200px;margin-right:10px;"></el-input>
            </div>
            <div class="listtitle" style="margin-top:10px;"><strong>四大战略性新兴支柱产业人才</strong></div>
            <div class="cont">
                <el-input size="small" v-model="list[2].sadName" placeholder="产业名称" style="width:200px; margin-right:10px;"></el-input>
                <el-input size="small" v-model="list[2].percentage" placeholder="现量" onkeyup="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}" style="width:200px;margin-right:10px;"></el-input>
                <el-input size="small" v-model="list[2].percentageMark" placeholder="变量" onkeyup="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}" style="width:200px;margin-right:20px;"></el-input>
                <el-radio v-model="list[2].mark" label="1">增长</el-radio>
                <el-radio v-model="list[2].mark" label="2">下降</el-radio>
            </div>
            <div class="cont">
                <el-input size="small" v-model="list[3].sadName" placeholder="产业名称" style="width:200px; margin-right:10px;"></el-input>
                <el-input size="small" v-model="list[3].percentage" placeholder="现量" onkeyup="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}" style="width:200px;margin-right:10px;"></el-input>
                <el-input size="small" v-model="list[3].percentageMark" placeholder="变量" onkeyup="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}" style="width:200px;margin-right:20px;"></el-input>
                <el-radio v-model="list[3].mark" label="1">增长</el-radio>
                <el-radio v-model="list[3].mark" label="2">下降</el-radio>
            </div>
            <div class="cont">
                <el-input size="small" v-model="list[4].sadName" placeholder="产业名称" style="width:200px; margin-right:10px;"></el-input>
                <el-input size="small" v-model="list[4].percentage" placeholder="现量" onkeyup="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}" style="width:200px;margin-right:10px;"></el-input>
                <el-input size="small" v-model="list[4].percentageMark" placeholder="变量" onkeyup="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}" style="width:200px;margin-right:20px;"></el-input>
                <el-radio v-model="list[4].mark" label="1">增长</el-radio>
                <el-radio v-model="list[4].mark" label="2">下降</el-radio>
            </div>
            <div class="cont">
                <el-input size="small" v-model="list[5].sadName" placeholder="产业名称" style="width:200px; margin-right:10px;"></el-input>
                <el-input size="small" v-model="list[5].percentage" placeholder="现量" onkeyup="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}" style="width:200px;margin-right:10px;"></el-input>
                <el-input size="small" v-model="list[5].percentageMark" placeholder="变量" onkeyup="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}" style="width:200px;margin-right:20px;"></el-input>
                <el-radio v-model="list[5].mark" label="1">增长</el-radio>
                <el-radio v-model="list[5].mark" label="2">下降</el-radio>
            </div>
            <div class="listtitle" style="margin-top:10px;"><strong>四大特色优势传统产业人才</strong></div>
            <div class="cont">
                <el-input size="small" v-model="list[6].sadName" placeholder="产业名称" style="width:200px; margin-right:10px;"></el-input>
                <el-input size="small" v-model="list[6].percentage" placeholder="现量" onkeyup="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}" style="width:200px;margin-right:10px;"></el-input>
                <el-input size="small" v-model="list[6].percentageMark" placeholder="变量" onkeyup="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}" style="width:200px;margin-right:20px;"></el-input>
                <el-radio v-model="list[6].mark" label="1">增长</el-radio>
                <el-radio v-model="list[6].mark" label="2">下降</el-radio>
            </div>
            <div class="cont">
                <el-input size="small" v-model="list[7].sadName" placeholder="产业名称" style="width:200px; margin-right:10px;"></el-input>
                <el-input size="small" v-model="list[7].percentage" placeholder="现量" onkeyup="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}" style="width:200px;margin-right:10px;"></el-input>
                <el-input size="small" v-model="list[7].percentageMark" placeholder="变量" onkeyup="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}" style="width:200px;margin-right:20px;"></el-input>
                <el-radio v-model="list[7].mark" label="1">增长</el-radio>
                <el-radio v-model="list[7].mark" label="2">下降</el-radio>
            </div>
            <div class="cont">
                <el-input size="small" v-model="list[8].sadName" placeholder="产业名称" style="width:200px; margin-right:10px;"></el-input>
                <el-input size="small" v-model="list[8].percentage" placeholder="现量" onkeyup="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}" style="width:200px;margin-right:10px;"></el-input>
                <el-input size="small" v-model="list[8].percentageMark" placeholder="变量" onkeyup="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}" style="width:200px;margin-right:20px;"></el-input>
                <el-radio v-model="list[8].mark" label="1">增长</el-radio>
                <el-radio v-model="list[8].mark" label="2">下降</el-radio>
            </div>
            <div class="cont">
                <el-input size="small" v-model="list[9].sadName" placeholder="产业名称" style="width:200px; margin-right:10px;"></el-input>
                <el-input size="small" v-model="list[9].percentage" placeholder="现量" onkeyup="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}" style="width:200px;margin-right:10px;"></el-input>
                <el-input size="small" v-model="list[9].percentageMark" placeholder="变量" onkeyup="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}" style="width:200px;margin-right:20px;"></el-input>
                <el-radio v-model="list[9].mark" label="1">增长</el-radio>
                <el-radio v-model="list[9].mark" label="2">下降</el-radio>
            </div>
            <div class="listtitle" style="margin-top:10px;"><strong>四大生产性服务人才</strong></div>
            <div class="cont">
                <el-input size="small" v-model="list[10].sadName" placeholder="产业名称" style="width:200px; margin-right:10px;"></el-input>
                <el-input size="small" v-model="list[10].percentage" placeholder="现量" onkeyup="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}" style="width:200px;margin-right:10px;"></el-input>
                <el-input size="small" v-model="list[10].percentageMark" placeholder="变量" onkeyup="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}" style="width:200px;margin-right:20px;"></el-input>
                <el-radio v-model="list[10].mark" label="1">增长</el-radio>
                <el-radio v-model="list[10].mark" label="2">下降</el-radio>
            </div>
            <div class="cont">
                <el-input size="small" v-model="list[11].sadName" placeholder="产业名称" style="width:200px; margin-right:10px;"></el-input>
                <el-input size="small" v-model="list[11].percentage" placeholder="现量" onkeyup="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}" style="width:200px;margin-right:10px;"></el-input>
                <el-input size="small" v-model="list[11].percentageMark" placeholder="变量" onkeyup="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}" style="width:200px;margin-right:20px;"></el-input>
                <el-radio v-model="list[11].mark" label="1">增长</el-radio>
                <el-radio v-model="list[11].mark" label="2">下降</el-radio>
            </div>
            <div class="cont">
                <el-input size="small" v-model="list[12].sadName" placeholder="产业名称" style="width:200px; margin-right:10px;"></el-input>
                <el-input size="small" v-model="list[12].percentage" placeholder="现量" onkeyup="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}" style="width:200px;margin-right:10px;"></el-input>
                <el-input size="small" v-model="list[12].percentageMark" placeholder="变量" onkeyup="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}" style="width:200px;margin-right:20px;"></el-input>
                <el-radio v-model="list[12].mark" label="1">增长</el-radio>
                <el-radio v-model="list[12].mark" label="2">下降</el-radio>
            </div>
            <div class="cont">
                <el-input size="small" v-model="list[13].sadName" placeholder="产业名称" style="width:200px; margin-right:10px;"></el-input>
                <el-input size="small" v-model="list[13].percentage" placeholder="现量" onkeyup="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}" style="width:200px;margin-right:10px;"></el-input>
                <el-input size="small" v-model="list[13].percentageMark" placeholder="变量" onkeyup="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}" style="width:200px;margin-right:20px;"></el-input>
                <el-radio v-model="list[13].mark" label="1">增长</el-radio>
                <el-radio v-model="list[13].mark" label="2">下降</el-radio>
            </div>
            <div style="text-align:center; padding:20px 0;">
                <el-button type="primary" @click="updateup">提交保存</el-button>
            </div>
            <div class="topchange" style="margin-top:50px;">
                <el-input size="small" v-model="num2" placeholder="请输入一个数字" style="width:200px;margin-right:5px;"  onkeyup="value=value.replace(/[^\d\.]/g,'')" ></el-input><el-button type="primary" size="small" @click="changedata2">更改数据</el-button><el-button type="primary" size="small" @click="resetdata2">重置数据</el-button>
            </div>
            <div class="listtitle" style="margin-top:10px;"><strong>人才供需侧经验值对比</strong></div>
            <div class="cont">
                企业需求:

                <el-input size="small" v-for="(item,index) in list2[0].bcValue" :key="index" v-model="list2[0].bcValue[index]" placeholder="" style="width:100px; margin-right:6px;"></el-input>

            </div>
            <div class="cont">
                人才现状： 
                <el-input size="small" v-for="(item,index) in list2[1].bcValue" :key="index" v-model="list2[1].bcValue[index]" placeholder="" style="width:100px; margin-right:6px;"></el-input>
            </div>
            <div class="cont">
                供 需 比： 
                <el-input size="small" v-for="(item,index) in list2[2].bcValue" :key="index" v-model="list2[2].bcValue[index]" placeholder="" style="width:100px; margin-right:6px;"></el-input>
            </div>
            <div class="listtitle" style="margin-top:10px;"><strong>人才供需侧学历对比</strong></div>
            <div class="cont">
                企业需求： 
                <el-input size="small" v-for="(item,index) in list2[3].bcValue" :key="index" v-model="list2[3].bcValue[index]" placeholder="" style="width:100px; margin-right:6px;"></el-input>
            </div>
            <div class="cont">
                学历状况： 
                <el-input size="small" v-for="(item,index) in list2[4].bcValue" :key="index" v-model="list2[4].bcValue[index]" placeholder="" style="width:100px; margin-right:6px;"></el-input>
                <span>从左至右：初中及以下，高中，大专，本科，研究生，硕士，博士</span>
            </div>
            <div class="listtitle" style="margin-top:10px;"><strong>人才供需侧经验值对比</strong></div>
            <div class="cont">
                企业需求： 
                <el-input size="small" v-for="(item,index) in list2[5].bcValue" :key="index" v-model="list2[5].bcValue[index]" placeholder="" style="width:100px; margin-right:6px;"></el-input>
            </div>
            <div class="cont">
                人才现状：
                <el-input size="small" v-for="(item,index) in list2[6].bcValue" :key="index" v-model="list2[6].bcValue[index]" placeholder="" style="width:100px; margin-right:6px;"></el-input>
                <span>从左至右：应届生或无经验，一年以内，1-3年，3-5年，5-10年，10年以上</span>
            </div>
            <div class="listtitle" style="margin-top:10px;"><strong>人才供需侧薪值对比</strong></div>
            <div class="cont">
                全国人才期望平均薪资：
                <el-input size="small" v-for="(item,index) in list2[7].bcValue" :key="index" v-model="list2[7].bcValue[index]" placeholder="" style="width:100px; margin-right:6px;"></el-input>
            </div>
            <div class="cont">
                全国企业招聘薪资： 
                <el-input size="small" v-for="(item,index) in list2[8].bcValue" :key="index" v-model="list2[8].bcValue[index]" placeholder="" style="width:100px; margin-right:6px;"></el-input>
            </div>
            <div class="cont">
                南昌人才期望平均薪资： 
                <el-input size="small" v-for="(item,index) in list2[9].bcValue" :key="index" v-model="list2[9].bcValue[index]" placeholder="" style="width:100px; margin-right:6px;"></el-input>
            </div>
            <div class="cont">
                南昌企业招聘薪资： 
                <el-input size="small" v-for="(item,index) in list2[10].bcValue" :key="index" v-model="list2[10].bcValue[index]" placeholder="" style="width:100px; margin-right:6px;"></el-input>
            </div>
            <div style="text-align:center; padding:20px 0;">
                <el-button type="primary" @click="updatedown">提交保存</el-button>
            </div>
        </div>
    </div>
</template>
<script>
import serverurl from '@/api/bigdata'
import _api from '@/api/index'
export default {
    data(){
        return {
            num:"",
            num2:"",
            list:[
                {lineid:1,type:1,sadName:"上饶人才总数",percentage:"",percentageMark:"",mark:"1"},
                {lineid:2,type:2,sadName:"上饶企业总数",percentage:"",percentageMark:"",mark:"1"},
                {lineid:3,type:3,sadName:"",percentage:"",percentageMark:"",mark:"1"},
                {lineid:4,type:3,sadName:"",percentage:"",percentageMark:"",mark:"1"},
                {lineid:5,type:3,sadName:"",percentage:"",percentageMark:"",mark:"1"},
                {lineid:6,type:3,sadName:"",percentage:"",percentageMark:"",mark:"1"},
                {lineid:7,type:4,sadName:"",percentage:"",percentageMark:"",mark:"1"},
                {lineid:8,type:4,sadName:"",percentage:"",percentageMark:"",mark:"1"},
                {lineid:9,type:4,sadName:"",percentage:"",percentageMark:"",mark:"1"},
                {lineid:10,type:4,sadName:"",percentage:"",percentageMark:"",mark:"1"},
                {lineid:11,type:5,sadName:"",percentage:"",percentageMark:"",mark:"1"},
                {lineid:12,type:5,sadName:"",percentage:"",percentageMark:"",mark:"1"},
                {lineid:13,type:5,sadName:"",percentage:"",percentageMark:"",mark:"1"},
                {lineid:14,type:5,sadName:"",percentage:"",percentageMark:"",mark:"1"},
            ],
            list2:[
                {version:1,isDelete:false,lineid:15,type:6,bcName:"企业需求",bcValue:[0,0,0,0,0,0,0,0,0,0,0,0]},
                {version:1,isDelete:false,lineid:16,type:6,bcName:"人才现状",bcValue:[0,0,0,0,0,0,0,0,0,0,0,0]},
                {version:1,isDelete:false,lineid:17,type:6,bcName:"供 需 比",bcValue:[0,0,0,0,0,0,0,0,0,0,0,0]},
                {version:1,isDelete:false,lineid:18,type:7,bcName:"企业需求",bcValue:[0,0,0,0,0,0,0]},
                {version:1,isDelete:false,lineid:19,type:7,bcName:"学历状况",bcValue:[0,0,0,0,0,0,0]},
                {version:1,isDelete:false,lineid:20,type:8,bcName:"企业需求",bcValue:[0,0,0,0,0,0]},
                {version:1,isDelete:false,lineid:21,type:8,bcName:"人才现状",bcValue:[0,0,0,0,0,0]},
                {version:1,isDelete:false,lineid:22,type:9,bcName:"全国人才期望平均薪资",bcValue:[0,0,0,0,0,0,0]},
                {version:1,isDelete:false,lineid:23,type:9,bcName:"全国企业招聘薪资",bcValue:[0,0,0,0,0,0,0]},
                {version:1,isDelete:false,lineid:24,type:9,bcName:"南昌人才期望平均薪资",bcValue:[0,0,0,0,0,0,0]},
                {version:1,isDelete:false,lineid:25,type:9,bcName:"南昌企业招聘薪资",bcValue:[0,0,0,0,0,0,0]},
            ],
            newarr:[]
        }
    },
    created(){
        this.getList1()
        this.getList2()
    },
    methods:{
        resetdata(){
            _api.get(serverurl.reset2,{}).then(res=>{
                console.log(res)
                if(res.success){
                    this.$message.success("重置成功！")
                    this.getList1()
                }
            })
        },
        changedata(){//更改数据
            if(this.num==""){
                this.$message.error("请输入一个变换系数")
                return
            }
            _api.get(serverurl.random2,{num:this.num}).then(res=>{
                console.log(res)
                if(res.success){
                    this.$message.success("更改成功！")
                    this.getList1()
                }
            })
        },
        resetdata2(){
            _api.get(serverurl.reset3,{}).then(res=>{
                console.log(res)
                if(res.success){
                    this.$message.success("重置成功！")
                    this.getList2()
                }
            })
        },
        changedata2(){//更改数据
            if(this.num2==""){
                this.$message.error("请输入一个变换系数")
                return
            }
            _api.get(serverurl.random3,{num:this.num2}).then(res=>{
                console.log(res)
                if(res.success){
                    this.$message.success("更改成功！")
                    this.getList2()
                }
            })
        },
        getList2(){
            _api.get(serverurl.supplylist2,{}).then(res=>{
                console.log(res)
                if(res.success){
                    this.list2=res.data
                    for(let item of this.list2){
                        item.bcValue=item.bcValue.split(",")
                    }
                }
            })
        },
        getList1(){
            _api.get(serverurl.supplylist,{}).then(res=>{
                console.log(res)
                if(res.success){
                    this.list=res.data
                }
            })
        },
        updateup(){
            _api.post(serverurl.supplyinsert,{supplyAndDemand:this.list}).then(res=>{
                console.log(res)
                if(res.success){
                    this.$message.success("更新成功！")
                    this.getList1()
                }
            })
        },
        updatedown(){
            this.newarr=JSON.parse(JSON.stringify(this.list2))

            for(let item of this.newarr){
                item.bcValue=item.bcValue.join(",")
            }
            _api.post(serverurl.supplyinsert2,{barChartParam:this.newarr}).then(res=>{
                console.log(res)
                if(res.success){
                    this.$message.success("更新成功！")
                    this.getList2()
                }
            })
        }
    }
}
</script>
<style scoped>
@import "../../assets/css/bigdata.css";
.cont{ padding:12px 0;}
.topchange{ text-align: center; padding-bottom: 20px;}
</style>